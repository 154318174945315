import './App.css';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import InfoIcon from '@mui/icons-material/Info';
import WorkIcon from '@mui/icons-material/Work';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import BrushIcon from '@mui/icons-material/Brush';
import BaThesis from './Components/BaThesis';
import Internship from './Components/Internship';
import Aboutme from './Components/Aboutme'
import Design from './Components/Design';
import { useState } from 'react';
function App() {
  const [detailBaThesis, setDetailBaThesis] = useState(false);
  const [detailInt, setDetailInt] = useState(false);
  const [abouteMe, setAbouteMe] = useState(false);
  const [designP, setDesignP] = useState(false)

 function showDetail(e){
if(detailBaThesis === false){
  setDetailBaThesis(true)
  setDetailInt(false)
    setDesignP(false)
    setAbouteMe(false)
}else if(detailBaThesis ===true){
  setDetailBaThesis(false)
}
 }
 function showInt(e){
  if(detailInt === false){
    setDetailInt(true)
    setDesignP(false)
    setAbouteMe(false)
    setDetailBaThesis(false)
  }else if(detailInt === true){
    setDetailInt(false)
  }
 }
 function showAme(e){
  if(abouteMe === false){
    setAbouteMe(true)
    setDetailInt(false)
    setDesignP(false)
    setDetailBaThesis(false)
  }else if(abouteMe === true){
    setAbouteMe(false)
  }
 }
 function showDsn(e){
  if(designP === false){
    setDesignP(true)
    setAbouteMe(false)
    setDetailInt(false)
    setDetailBaThesis(false)
  }else if(designP === true){
    setDesignP(false)
  }
 }
  return (
    <div className="App">
      <div id="body">
       
     <List  sx={{ width: '90%',
      //bgcolor: '#FEF5E5'
       }}>
      <ListItem 
      id='listId'
      onClick={showAme}>
        
        <ListItemAvatar>
          <Avatar style={abouteMe ? { backgroundColor: "#34495e" } : {backgroundColor: "#2c3e50" }}>
            <InfoIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText>
          About me
        </ListItemText>
      </ListItem>
      {abouteMe? <Aboutme/> : <div></div>}
      <ListItem 
      id='listId'
      onClick={showInt}>
        <ListItemAvatar>
        <Avatar style={detailInt ? { backgroundColor: "#34495e" } : {backgroundColor: "#2c3e50" }}>
          <WorkIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText>
          Internship
        </ListItemText>
      </ListItem>
    {detailInt ? <Internship /> : <div></div>}
      <ListItem 
      id='listId'
      onClick={showDetail}>
        <ListItemAvatar>
        <Avatar style={detailBaThesis ? { backgroundColor: "#34495e" } : {backgroundColor: "#2c3e50" }}>
            <HistoryEduIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText>
          Bachelor thesis
        </ListItemText>
      </ListItem>
      {detailBaThesis? <BaThesis /> : <div></div>}
      <ListItem 
      id='listId'
      onClick={showDsn}>
   
        <ListItemAvatar>
        <Avatar style={designP ? { backgroundColor: "#34495e" } : {backgroundColor: "#2c3e50" }}>
            <BrushIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText>
          Design Projects
        </ListItemText>
      </ListItem>
     {designP ? <Design /> : <div></div>} 
     </List>
     
     </div>
    </div>
  );
}

export default App;
