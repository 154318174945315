function Internship() {
    // https://indd.adobe.com/view/86ea48ff-1326-4bc8-bf9c-bf9534c3a522

  return (
    <div className="Internship">
      <div id=""
      style={{ textAlign: 'justify'}}>
        <h3>Abano</h3>
     <p id="text">
     <b>From January till April of 2022, I had an internship position in Abano. </b>
     <br></br>
     Abano belongs to Konato, which belongs to the parent company Cronos.
<br></br>
<br></br>
     A Cronos timesheet application:<br></br>
At the end of the month, <br></br>
all companies under Cronos have to send worklogs, which is done manually and monthly by a project leader. <br></br>
My internship assignment consisted of developing a timesheet application, <br></br>
in which an overview of worklogs per project of a particular subsidiary is visualized. <br></br>
This would allow the project leader to approve the logs and then send them to Cronos.<br></br>
For the development of the web application I was given the freedom to use frameworks and libraries of my choice. <br></br>
Before I could start the development process I had to do the API test, and so I made UML and use cases diagrams.<br></br>
 Once it was clear which elements I needed for the app, I started testing different libraries to find the right tool for the visualization of the worklogs.
 <br></br>
I used three tools for the visualization of the worklogs. <br></br>
First I used different “table” visualization tools, but I always ran into the same problem, which was that I could only filter on columns or headers.<br></br>
 To solve this problem I started working with “calender” based tools, and that is how I finally found the “day pilot-React-Schedule” library. <br></br>
 This library is a scheduler calendar where you can bind events by date. I was able to create a timesheet visualizer with this tool.<br></br>


     </p>
 
   
     </div>
    </div>
  );
}

export default Internship;
