import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import me from '../assets/me.png'
function Aboutme() {
    // https://indd.adobe.com/view/86ea48ff-1326-4bc8-bf9c-bf9534c3a522

  return (
    <div className="aboutme">
      <div id=""
      style={{ textAlign: 'justify'}}>
        
        <h3>
            About me</h3>
            <div 
            id="about"
            style={{}}>
               
  <p>
        I am a frontend developer based in Brussels/Tienen. <br>
        </br>
        Passionated about webdesign.<br></br>
        Occasional fashion designer as a hobby.<br></br>
        Graduated from erasmus hogeschool Brussels with a bachelor degree in Multimedia & Creative Technology. <br></br>
        On this website you can find projects that demonstrate my skills.<br></br>
        Enjoy
        -Manish Nepali
        <br></br>
    </p>
    <img src={me} 
        id="me"></img>
    </div> 
    <Stack spacing={2} direction="row">
     <a href="https://www.linkedin.com/in/manish-nepali-8000a6177/" target="_blank"> 
     <Button variant="outlined"
     > linkedIn</Button>
      </a> 
      </Stack>
   
     </div>
    </div>
  );
}

export default Aboutme;