import Slider from "react-slick";
import lomani from '../assets/lomamiRiverLogo2.png';
import menu from '../assets/board2finalv1.png';
import fab from '../assets/4.png';
import lysn1 from '../assets/lysn1.png'
import lysn2 from '../assets/lysn2.png'
import cr from '../assets/cr.png'

function Design(){
    const settings = {
          dots: true,
          infinite: false,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1
        };
  return (
    <div className="design">
      <div id=""
      style={{ textAlign: 'justify'}}>
        <h3>Design projects</h3>
     
        <Slider {...settings}
        style={{justifyContent: "baseline"}}>
          <div>
          <p>logo for "lomani"</p>
            <img src={lomani} style={{ width: "30%"}}></img>
           
          </div>
          <div>
          <p>menuboard design for "coffee cup"</p>
          <img src={menu} style={{minWidth: "80%",maxWidth: "100%"}}></img>
         
          </div>
          <div>
          <p>logo design for "crossroad"</p>
          <img src={cr} style={{width: "30%"}}></img>
          
          </div>
          <div>
          <p>logo design for "fa babe beauty"</p>
          <img src={fab} style={{width: "30%",maxWidth: "80%"}}></img>
         
          </div>
          <div>
          <p>sweater design for "lysn (clothing start up)" 1</p>
          <img src={lysn1} style={{width: "30%",maxWidth: "80%"}}></img>
         
          </div>
          <div>
          <p>sweater design for "lysn (clothing start up)" 2</p>
          <img src={lysn2} style={{width: "30%",maxWidth: "80%"}}></img>
          
          </div>
          
        </Slider>
   
     </div>
    </div>
  );
}

export default Design;
