import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
function BaThesis() {
    // https://indd.adobe.com/view/86ea48ff-1326-4bc8-bf9c-bf9534c3a522

  return (
    <div className="App">
      <div id=""
      style={{ textAlign: 'justify'}}>
        <h3>Nav It</h3>
     <p>
        A research on 'how to you use technology to encourage people to explore a city around which there is prejudice?'. <br></br>
        To which I came to the conclusion that a mobile weeb application was the answer.
        Nav It.<br></br>
        Nav It was developed using React.js, mapbox.gl and firebase libraries. 
     </p>
     <Stack spacing={2} direction="row">
     <a href="https://www.navit.site/" target="_blank"> 
     <Button variant="outlined"
     style={{color: '#2c3e50'}}> Visit Nav It</Button>
      </a> 
     <a href="https://indd.adobe.com/view/86ea48ff-1326-4bc8-bf9c-bf9534c3a522" target="_blank"> 
     <Button variant="outlined"
     style={{color: '#2c3e50'}}>Read Bachelor Thesis</Button>
     </a>
     </Stack>
   
     </div>
    </div>
  );
}

export default BaThesis;
